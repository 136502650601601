<template>
    <div class="mod-config">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item>
                <el-button style="margin: 20px 0px 0px 20px;" class="btn" type="primary" @click="addOrUpdate()">+ 新增</el-button>
<!--                <el-button type="primary" size="small" @click="addOrUpdate(1)">修改</el-button>-->
<!--                <el-button type="primary" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">删除</el-button>-->
            </el-form-item>
        </el-form>
        <div style="margin-left: 20px; margin-right: 20px;">
            <el-table
                :data="dataList"
                border
                lazy
                :load="loadNode"
                ref="org_treeTable"
                row-key="id"
                @row-click="selectRow"
                node-key="id"
                v-loading="dataListLoading"
                @selection-change="selectionChangeHandle"
                :tree-props="org_props"
                style="width: 100%;">
                <!--<el-table-column
                    type="selection"
                    header-align="center"
                    align="center"
                    width="50">
                </el-table-column>-->
                <el-table-column
                    prop="name"
                    header-align="center"
                    align="left"
                    label="名称">
                </el-table-column>
                <el-table-column
                    prop="description"
                    header-align="center"
                    align="center"
                    label="描述">
                </el-table-column>
                <el-table-column
                    prop="orgCode"
                    header-align="center"
                    align="center"
                    width="120"
                    label="编码">
                </el-table-column>
                <el-table-column
                    prop="orgType"
                    header-align="center"
                    align="center"
                    width="120"
                    label="组织类型">
                    <template slot-scope="scope">
                        {{scope.row.orgType|orgTypeFilter}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="userNum"
                    header-align="center"
                    align="center"
                    width="120"
                    label="员工数量">
                </el-table-column>
                <el-table-column
                    fixed="right"
                    header-align="center"
                    align="center"
                    width="220"
                    label="操作">
                    <template slot-scope="scope">
                        <el-tooltip content="编辑" placement="top" :open-delay="200">
                            <div
                                class="search-table-icon search-table-icon_edit"
                                @click="addOrUpdateHandle(scope.row)"
                                type="text"
                            ></div>
                        </el-tooltip>
                        <el-tooltip content="删除" placement="top" :open-delay="200">
                            <div
                                class="search-table-icon search-table-icon_delete"
                                @click="deleteHandle(scope.row.id)"
                                type="text"
                            ></div>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" :parent_id="parent_id" :parent_name="parent_name"
                       @refreshDataList="getDataList" :orgType_options="orgType_options"></add-or-update>
    </div>
</template>

<script>
import {getDictData} from '@/utils';
import AddOrUpdate from './orgUnit-add-or-update';

let orgTypeMap = {};
export default {
    data() {
        return {
            org_props: {
                children: 'children',
                hasChildren: 'hasChildren'
            },
            dataForm: {
                key: ''
            },
            dataList: [],
            orgType_options: [],
            pageIndex: 1,
            pageSize: 10,
            totalPage: 0,
            parent_id: -1,
            parent_name: '顶级组织',
            dataListLoading: false,
            dataListSelections: [],
            addOrUpdateVisible: false,
        };
    },
    components: {
        AddOrUpdate
    },
    created() {
        this.loadOrgTypeData();
    },
    filters: {
        orgTypeFilter(val) {
            return orgTypeMap[val];
        }
    },
    methods: {
        selectRow(row) {
            this.$refs.org_treeTable.toggleRowSelection(row);
        },
        loadOrgTypeData() {
            this.$client.getDicData('org_type').then((res) => {
                this.orgType_options = res.data;
                for (const item of this.orgType_options) {
                    orgTypeMap[item.itemKey] = item.itemValue;
                }
                this.getDataList();
            });
        },
        loadNode(row, node, resolve) {
            this.$client.getOrgUnitListByAdmin({parent: row.id}).then((data) => {
                const list = [];
                for (const item of data.list) {
                    item.hasChildren = item.isLeaf === 1 ? false : true;
                    item.parentName = row.name;
                    list.push(item);
                }
                //row.children=list;
                resolve(list);
            });
        },
        // 获取数据列表
        getDataList() {
            this.dataListLoading = true;
            this.dataList = [];
            this.$client.getOrgUnitListByAdmin({parent: -1}).then((data) => {
                const list = [];
                for (const item of data.list) {
                    item.hasChildren = item.isLeaf === 1 ? false : true;
                    item.parentName = '顶级组织';
                    list.push(item);
                }
                this.dataList = list;
                this.dataListLoading = false;
            });
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.pageIndex = 1;
            this.getDataList();
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val;
            this.getDataList();
        },
        // 多选
        selectionChangeHandle(val) {
            this.dataListSelections = val;
        },
        // 新增 / 修改
        addOrUpdate(status) {
            if (status) {
                //修改
                if (!this.preValidate()) {
                    return;
                }
                this.addOrUpdateHandle(this.dataListSelections[0]);
            } else if (this.dataListSelections.length === 0) {
                this.addRootOrg();
            } else if (this.dataListSelections.length > 0) {
                //已选中节点为父节点添加
                if (!this.preValidate(1)) {
                    return;
                }
                this.addChildOrg(this.dataListSelections[0]);
            }
        },
        addOrUpdateHandle(row) {
            this.addOrUpdateVisible = true;
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(Object.assign({}, row));
            });
        },
        addChildOrg(row) {
            this.parent_id = row.id;
            this.parent_name = row.name;
            this.addOrUpdateHandle();
        },
        addRootOrg() {
            this.parent_id = -1;
            this.parent_name = '顶级组织';
            this.addOrUpdateHandle();
        },
        // 删除
        deleteHandle(id) {
            if (!id) {
                return;
            }
            const ids = [];
            ids.push(id);
            this.$confirm(`确定删除选中数据？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client.delAllOrgUnit(ids).then((data) => {
                    if (data && data.code === 0) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                                this.getDataList();
                            }
                        });
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            });
        },
        preValidate(isSingle) {
            if (this.dataListSelections == null || this.dataListSelections.length < 1) {
                this.$message({
                    type: 'warning',
                    message: '请先选择待操作数据'
                });
                return false;
            }
            //是否只能选一条数据的校验
            if (isSingle) {
                if (this.dataListSelections.length > 1) {
                    this.$message({
                        type: 'warning',
                        message: '只允许对一条进行操作'
                    });
                    return false;
                }
            }
            return true;
        },
    }
};
</script>
<style lang="less">
    .btn{
        border-radius: 4px;
    }
</style>
