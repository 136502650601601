<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-container>
      <el-aside width="220px">
        <span class="item-label">组织树:</span>
        <el-tree
          ref="orgTree"
          :data="treeList"
          :props="props"
          node-key="id"
          :load="loadOrgAtUnit"
          @node-click="orgClick"
          :highlight-current="true"
          lazy>
        </el-tree>
      </el-aside>
      <el-main>
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px">
      <el-form-item label="所属组织" prop="orgName">
        <el-input v-model="dataForm.parentId" placeholder="请选择左则组织树" style="display: none;"></el-input>
        <el-input v-model="dataForm.parentName" placeholder="请选择左则组织树" readOnly></el-input>
      </el-form-item>
    <el-form-item label="名称" prop="name">
      <el-input v-model="dataForm.name" placeholder="名称"></el-input>
    </el-form-item>
    <el-form-item label="描述" prop="description">
      <el-input v-model="dataForm.description" placeholder="描述" type="textarea"></el-input>
    </el-form-item>
    <el-form-item label="编码" prop="orgCode">
      <el-input v-model="dataForm.orgCode" placeholder="编码"></el-input>
    </el-form-item>
    <el-form-item label="排序" prop="sort">
      <el-input v-model="dataForm.sort" type="number" min="0" placeholder="排序"></el-input>
    </el-form-item>
    <el-form-item label="组织类型" prop="orgType">
      <el-select v-model="dataForm.orgType" clearable placeholder="请选择">
        <el-option
          v-for="item in orgType_options"
          :key="item.itemKey"
          :label="item.itemValue"
          :value="item.itemKey">
        </el-option>
      </el-select>
    </el-form-item>
    </el-form>
      </el-main>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
//时间区间组件快捷键
import dayjs from 'dayjs';
export default {
    props: ['orgType_options', 'parent_id', 'parent_name'],
    data() {
        return {
            DateTimePickerOption: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            props: {
                label: 'name',
                children: 'children',
                isLeaf: 'isLeaf'
            },
            pickerTime: [],
            treeList: [],
            visible: false,
            dataForm: {
                id: 0,
                unitId: '',
                name: '',
                parentId: -1,
                parentName: '顶级组织',
                description: '',
                orgCode: '',
                orgType: '',
                sort: '0',
                timeBegin: '',
                timeEnd: '',
                status: true,
            },
            currentRow: null,
            dataRule: {
                name: [
                    {required: true, message: '名称不能为空', trigger: 'blur'},
                    {trigger: 'blur',
                        validator(rule, value, callback) {
                            if (value.match(/^\s+$/g)) {
                                callback(new Error('名称不能全为空格!'));
                            } else {callback();}
                        }
                    },
                ],
                orgCode: [
                    {required: true, message: '编码不能为空', trigger: 'blur'},
                ],
                orgType: [
                    {required: true, message: '组织类型不能为空', trigger: 'blur'}
                ],
                timeBegin: [
                    {required: true, message: '生效时间不能为空', trigger: 'blur'}
                ],
                timeEnd: [
                    {required: true, message: '失效时间不能为空', trigger: 'blur'}
                ],
                status: [
                    {required: true, message: '是否启用不能为空', trigger: 'blur'}
                ],
            }
        };
    },
    created() {
    },
    methods: {
        pickerTimeChange(val) {
            this.dataForm.timeBegin = val[0];
            this.dataForm.timeEnd = val[1];
        },
        orgClick(data) {
            if (this.currentRow) {
                const currentPath = this.currentRow.unitPath + this.currentRow.id + '/';
                if (data.id === this.currentRow.id) {
                    this.$message.error('不能选择该组织!');
                    return;
                }
                if (data.unitPath === currentPath) {
                    this.$message.error('不能选择该组织!');
                    return;
                }
            }
            this.dataForm.parentName = data.name;
            this.dataForm.parentId = data.id;
        },
        reloadTreeData() {
            this.$client.getOrgUnitListByAdmin({parent: -1})
                .then((data) => {
                    const children = [];
                    for (const item of data.list) {
                        item.isLeaf = item.isLeaf === 1 ? true : false;
                        children.push(item);
                    }
                    this.treeList = children;
                });
        },
        loadOrgAtUnit(node, resolve) {
            if (!node.data.id) {
                resolve([]);
                return;
            }
            this.$client.getOrgUnitListByAdmin({parent: node.data.id ? node.data.id : -1})
                .then((data) => {
                    const list = [];
                    for (const item of data.list) {
                        item.isLeaf = item.isLeaf === 1 ? true : false;
                        list.push(item);
                    }
                    resolve(list);
                });
        },
        init(row) {
            this.dataForm.id = row ? row.id : 0;
            this.visible = true;
            this.$nextTick(() => {
                this.$refs.dataForm.resetFields();
                this.reloadTreeData();
                this.dataForm.timeBegin = dayjs().format('YYYY-MM-DD HH:mm:ss');
                this.dataForm.timeEnd = dayjs('2099-12-30').format('YYYY-MM-DD HH:mm:ss');
                this.currentRow = null;
                if (this.dataForm.id) {
                    this.dataForm = row;
                    this.currentRow = row;

                    this.dataForm.status = row.status === 1 ? true : false;
                } else {
                    this.dataForm.parentId = this.parent_id;
                    this.dataForm.parentName = this.parent_name;
                }
                this.pickerTime = [this.dataForm.timeBegin, this.dataForm.timeEnd];
            });
        },
        // 表单提交
        dataFormSubmit() {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    const submit = this.dataForm.id ? this.$client.updateOrgUnit : this.$client.saveOrgUnit;
                    submit({
                        id: this.dataForm.id || undefined,
                        name: this.dataForm.name,
                        description: this.dataForm.description,
                        orgCode: this.dataForm.orgCode,
                        sort: this.dataForm.sort,
                        orgType: this.dataForm.orgType,
                        parentId: this.dataForm.parentId,
                        timeBegin: dayjs(this.dataForm.timeBegin).format('YYYY-MM-DD HH:mm:ss'),
                        timeEnd: dayjs(this.dataForm.timeEnd).format('YYYY-MM-DD HH:mm:ss'),
                        status: this.dataForm.status ? 1 : 0
                    }).then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500
                        });
                        this.visible = false;
                        this.$emit('refreshDataList');
                    });
                }
            });
        }
    }
};
</script>
